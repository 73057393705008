import styled, { css } from 'styled-components';
import { ReactComponent as BasePlayIcon } from './assets/play.svg';
import { ReactComponent as BasePauseIcon } from './assets/pause.svg';

const Button = styled.div`
  width: 50px;
  height: 33px;
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const icon = css`
  width: 20px;
  height: 20px;
`;

const PlayIcon = styled(BasePlayIcon)`
  ${icon}
  position: relative;
  left: 1px;
`;

const PauseIcon = styled(BasePauseIcon)`
  ${icon}
`;

function PlayButton({ playing, onClick }) {
  return <Button onClick={onClick}>{playing ? <PauseIcon /> : <PlayIcon />}</Button>;
}

export default PlayButton;
