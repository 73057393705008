const games = {
  'Kasparov vs Topalov, 1999': `1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 Bg7 5. Qd2 c6 6. f3 b5
  7. Nge2 Nbd7 8. Bh6 Bxh6 9. Qxh6 Bb7 10. a3 e5 11. O-O-O Qe7
  12. Kb1 a6 13. Nc1 O-O-O 14. Nb3 exd4 15. Rxd4 c5 16. Rd1 Nb6
  17. g3 Kb8 18. Na5 Ba8 19. Bh3 d5 20. Qf4+ Ka7 21. Rhe1 d4
  22. Nd5 Nbxd5 23. exd5 Qd6 24. Rxd4 cxd4 25. Re7+ Kb6
  26. Qxd4+ Kxa5 27. b4+ Ka4 28. Qc3 Qxd5 29. Ra7 Bb7 30. Rxb7
  Qc4 31. Qxf6 Kxa3 32. Qxa6+ Kxb4 33. c3+ Kxc3 34. Qa1+ Kd2
  35. Qb2+ Kd1 36. Bf1 Rd2 37. Rd7 Rxd7 38. Bxc4 bxc4 39. Qxh8
  Rd3 40. Qa8 c3 41. Qa4+ Ke1 42. f4 f5 43. Kc1 Rd2 44. Qa7 1-0`,
  'D Byrne vs Fischer, 1956': `1. Nf3 Nf6 2. c4 g6 3. Nc3 Bg7 4. d4 O-O 5. Bf4 d5 6. Qb3 dxc4
  7. Qxc4 c6 8. e4 Nbd7 9. Rd1 Nb6 10. Qc5 Bg4 11. Bg5 {11. Be2
  followed by 12. O-O would have been more prudent. The bishop
  move played allows a sudden crescendo of tactical points to be
  uncovered by Fischer. -- Wade} Na4 {!} 12. Qa3 {On 12. Nxa4
  Nxe4 and White faces considerable difficulties.} Nxc3 {At
  first glance, one might think that this move only helps White
  create a stronger pawn center; however, Fischer's plan is
  quite the opposite. By eliminating the Knight on c3, it
  becomes possible to sacrifice the exchange via Nxe4 and smash
  White's center, while the King remains trapped in the center.}
  13. bxc3 Nxe4 {The natural continuation of Black's plan.}
  14. Bxe7 Qb6 15. Bc4 Nxc3 16. Bc5 Rfe8+ 17. Kf1 Be6 {!! If
  this is the game of the century, then 17...Be6!! must be the
  counter of the century. Fischer offers his queen in exchange
  for a fierce attack with his minor pieces. Declining this
  offer is not so easy: 18. Bxe6 leads to a 'Philidor Mate'
  (smothered mate) with ...Qb5+ 19. Kg1 Ne2+ 20. Kf1 Ng3+
  21. Kg1 Qf1+ 22. Rxf1 Ne2#. Other ways to decline the queen
  also run into trouble: e.g., 18. Qxc3 Qxc5} 18. Bxb6 Bxc4+
  19. Kg1 Ne2+ 20. Kf1 Nxd4+ {This tactical scenario, where a
  king is repeatedly revealed to checks, is sometimes called a
  "windmill."} 21. Kg1 Ne2+ 22. Kf1 Nc3+ 23. Kg1 axb6 24. Qb4
  Ra4 25. Qxb6 Nxd1 26. h3 Rxa2 27. Kh2 Nxf2 28. Re1 Rxe1
  29. Qd8+ Bf8 30. Nxe1 Bd5 31. Nf3 Ne4 32. Qb8 b5 {Every piece
  and pawn of the black camp is defended. The white queen has
  nothing to do.} 33. h4 h5 34. Ne5 Kg7 35. Kg1 Bc5+ 36. Kf1
  Ng3+ {Now Byrne is hopelessly entangled in Fischer's mating
  net.} 37. Ke1 Bb4+ 38. Kd1 Bb3+ 39. Kc1 Ne2+ 40. Kb1 Nc3+
  41. Kc1 Rc2# 0-1`,

  'Beliavsky vs Nunn, 1985': `1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nbd7 7.Qd2
  c5 8.d5 Ne5 9.h3 Nh5 10.Bf2 f5 11.exf5 Rxf5 12.g4 Rxf3 13.gxh5
  Qf8 14.Ne4 Bh6 15.Qc2 Qf4 16.Ne2 Rxf2 17.Nxf2 Nf3+ 18.Kd1 Qh4
  19.Nd3 Bf5 20.Nec1 Nd2 21.hxg6 hxg6 22.Bg2 Nxc4 23.Qf2 Ne3+
  24.Ke2 Qc4 25.Bf3 Rf8 26.Rg1 Nc2 27.Kd1 Bxd3 0-1`
};

export default games;
