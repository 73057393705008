import * as Chess from 'chess.js';
import { useCallback, useEffect, useState } from 'react';
import * as Tone from 'tone';
import styled from 'styled-components';
import PlayButton from './PlayButton';
import games from './games.js';

const chess = new Chess();
const synth = new Tone.Synth().toDestination();

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  align-items: center;
`;

const ControlRow = styled.div`
  display: flex;
  align-items: center;
`;

const GameSelector = styled.select`
  margin-right: 16px;
  font-size: 18px;
  position: relative;
  top: -5px;
  padding: 2px;
`;

const Title = styled.div`
  font-size: 56px;
  font-family: 'Zen Tokyo Zoo', cursive;
  color: white;
  margin-top: 20px;
  margin-bottom: 100px;
`;

const ChessboardWrapper = styled.div`
  width: ${({ width }) => width}px;
`;

let moveIndex = 0;
let board;
let moves;

const resetChessboard = () => {
  moveIndex = 0;
  board = window.Chessboard('myBoard', 'start');
};

function App() {
  const [playing, setPlaying] = useState(false);
  const [selectedGame, setSelectedGame] = useState(Object.keys(games)[0]);
  const [chessboardWidth, setChessboardWidth] = useState(0);

  console.log({ selectedGame });

  useEffect(() => {
    setChessboardWidth(Math.min(0.9 * document.documentElement.clientWidth, 400));
    board = window.Chessboard('myBoard', 'start');
  }, [chessboardWidth]);

  useEffect(() => {
    if (!playing || !moves) {
      return;
    }

    if (chessboardWidth && moveIndex === moves.length) {
      resetChessboard();
    }

    const interval = setInterval(() => {
      if (moveIndex >= moves.length) {
        clearInterval(interval);
        setPlaying(false);
        return;
      }
      const nextMove = moves[moveIndex++];
      board.move(`${nextMove.from}-${nextMove.to}`);
      let note = nextMove.to.charAt(0);
      note = note === 'h' ? 'a' : note;
      const octave = nextMove.to.charAt(1);
      synth.triggerAttackRelease(`${note}${octave}`, '8n');
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [playing, chessboardWidth]);

  useEffect(() => {
    if (!selectedGame) {
      return;
    }
    setPlaying(false);
    const gamePgn = games[selectedGame];
    chess.load_pgn(gamePgn);
    moves = chess.history({ verbose: true });
    resetChessboard();
  }, [selectedGame]);

  const handleGameSelected = useCallback(
    (event) => {
      setSelectedGame(event.target.value);
    },
    [setSelectedGame]
  );

  return (
    <Wrapper>
      <Title>MusiChess</Title>
      <ControlRow>
        <GameSelector onChange={handleGameSelected}>
          {Object.keys(games).map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </GameSelector>

        <PlayButton
          playing={playing}
          onClick={() => {
            setPlaying((prev) => !prev);
          }}
        />
      </ControlRow>
      <ChessboardWrapper width={chessboardWidth}>
        <div id="myBoard" style={{ width: `100%` }}></div>
      </ChessboardWrapper>
    </Wrapper>
  );
}

export default App;
